
@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Jost');



.user-profile{
    background: #FFFFFF;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    padding-top: 50px;
    padding-bottom: 20px;
    min-height: 700px;
    margin-bottom: 30px;
}

.user-profile-title{
    margin-left:15%;
}
.user-profile-title-view{
    margin: auto;
    width: 570px;
    height: 50px;
    display: inline-grid;
    grid-template-columns: 33.33% 33.33% 33.33%;

}

.user-profile-title-view>button{
    border: 1px solid #CCCDD1;
    background-color:none;
}
.user-profile-title-view>button:hover{
    border: 1px solid #CCCDD1;   
    background-color: #192032;
    color: white;
}
.user-profile-btn-active{
    background-color: #192032;
    color: white;
}

.user-profile-show{
    padding-left:50px;
    padding-right: 50px;
    margin-top: 45px;
}
.user-profile-show-title{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    opacity: 0.8;
}
.user-profile-show-data{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    opacity: 0.3
}
.user-profile-show-btn{
    float: right;
    width: 100px;
    height: 35px;
    background: #E5E6E8;
    text-align: center;
}

.user-profile-show-btn>a{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #00071B;
    opacity: 0.5;   
    position: relative;
    top: 10%;
}
.user-profile-show-devider{
    height: 1px;
    background: #D3D2DB;
    mix-blend-mode: normal;
}

.user-profile-family{
    padding-left:50px;
    padding-right: 50px;
    margin-top: 45px;
}

.user-profile-family-member{
   border: 1px solid #E5E6E8;
   height: 171px;
   padding-top: 20px;
   padding-left: 5px;
   padding-right: 10px;
}
.user-profile-family-member-img{
    width: 60px;
    height: 60px;
    margin: auto;
    margin-bottom: 15px;
}

.user-profile-family-member-img>img{
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}
.user-profile-family-member-type{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    text-align: center;
}

.user-profile-family-member-detail{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-align: left;
    padding-left: -15px;

}

.user-profile-family-member-value{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    opacity: 0.3;
    padding-left: -15px;
}

.user-profile-address{
    padding-left:50px;
    padding-right: 50px;
    margin-top: 45px;
}
.user-profile-address-thead{
    background: #F2F2F3;
}
.user-profile-address-tbody{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #9FA2A9;
}
.user-address-td-detail{
    display: inline-grid;
    grid-template-columns: auto auto;
}
.user-address-edit-btn{
    width: 70px;
    height: 35px;
    background: #FFE5E6;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #FE7374;
    padding: 5px 10px 5px 10px  ;
}
.user-menu-section{
    background-color: #FFFFFF;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    height: 700px;
    margin-bottom: 30px;
}

.user-menu-profile{
    padding-top: 50px;
}

.user-menu-profile-img{
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 20px;

}
.user-menu-profile-img>img{
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.user-menu-profile-title{
    margin: auto;
    text-align: center;
}

.user-menu-profile-title>h3{

    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #00071B; 

}

.user-menu-profile-title>p{

    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #00071B;
    opacity: 0.5; 

}
.user-menu-profile-btn{
    margin: auto;
    text-align: center;
    width: 146px;
    height: 40px;
    background: #FE0002;
    margin-bottom: 20px;
}

.user-menu-profile-btn>a{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    top: 12%;

}
.user-menu-profile-divider{
    height: 1px;
    background: linear-gradient(90deg, rgba(19, 15, 64, 0.101961) 0%, #130F40 33.3%, #130F40 66.6%, rgba(19, 15, 64, 0.101961) 100%);
    mix-blend-mode: normal;
    opacity: 0.2;
    margin-bottom: 30px;
}
.user-menu2{
    
    padding-top: 15px;
    padding-bottom: 3px;
    padding-left: 15px;
}

.user-menu2:hover{
    background-color: #FFF2F2;
    color: #FE0002;
}

.user-menu2-active{
    padding-top: 15px;
    padding-bottom: 3px;
    padding-left: 15px;
    background-color: #FFF2F2;
}
.user-menu-profile-link {
    text-decoration: none;
    color: black;
}
.user-menu-profile-link>span {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    opacity: 0.7;
}
.user-menu-profile-link>span:hover {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #FE0002;
    opacity: 0.9;
}
.span-active{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #FE0002!important;
    opacity: 0.9!important;
}
.user-menu-profile-link>span>img {
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
}

.user-order{
    background: #FFFFFF;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    padding: 50px;
    min-height: 700px;
    padding-bottom: 6px;
    margin-bottom: 30px;
}
.user-order-title{
    margin-left:12%;
    margin-bottom: 30px;
}
.user-order-title-view{
    margin: auto;
    width: 570px;
    height: 50px;
    display: inline-grid;
    grid-template-columns: 25% 25% 25% 25%;
}
.user-order-title-view>a{
    border: 1px solid #CCCDD1;
    background-color:none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #80838D;
    padding-top: 8%;
}
.user-order-title-view>a:hover{
    border: 1px solid #CCCDD1;   
    background-color: #192032;
    color: white;
}
.user-order-btn-active{
    background-color: #192032;
    color: white;
}

.user-order-section{
    border: 1px solid #E5E6E8;
    margin-bottom: 30px;
    padding: 20px;
}
.order-section-timeline{
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
}
.order-section-timeline-item{
    width: 100%;
    display: inline-grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}
.order-section-timeline-circle-not-fill{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px dashed #FF0002;
    text-align: center;
}
.order-section-timeline-circle-fill{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FE0002;
    text-align: center;
}
.order-section-timeline-line-not-fill{
    opacity: 0.5;
    border: 1px dashed #FE0002;
    height: 1px;
    position: relative;
    top: 49%;
}
.order-section-timeline-line-blank-fill{
    border: 1px solid transparent;
    height: 1px;
    position: relative;
    top: 49%;
}
.order-section-timeline-line-fill{
    border: 1px solid #FE0002;
    height: 1px;
    position: relative;
    top: 49%;
}
.order-section-timeline-p-m-0{
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
}
.order-section-timeline-line-joint{
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, rgba(254, 0, 2, 0) 0%, #FFDADB
     100%);
     position: absolute;
     top: 37%;
}
.order-section-timeline-line-joint-in{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #FE0002;
    position: relative;
    margin: auto;
    top: 25%;
    left: 4%;
}

.order-section-timeline-circle-fill>img{
    max-width: 20px;
    max-height: 20px;
    position: relative;
    top: 20%;
}


.order-section-timeline-circle-not-fill>img{
    max-width: 20px;
    max-height: 20px;
    position: relative;
    top: 20%;
}
.order-section-timeline-title{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #00071B;
    opacity: 0.5;
    margin-top: 10px;
}
.user-order-section-number{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 7, 27, 0.5);
}

.user-order-section-number>a{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    opacity: 0.8;
}
.user-order-section-time{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
    opacity: 0.3;
}

.user-order-section-btn-detail{
    width: 100px;
    height: 35px;    
    background: #E5E6E8;
    border: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #8F929B;
    opacity: 0.5;
    text-align: center;
    position: relative;
    padding-top: 3%;
}

.user-order-section-btn-review{
    width: 120px;
    height: 35px;
    border: 1px solid #EEEFF0;
    box-sizing: border-box;
    background:none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6c6f75;
}
.order-section-detail-title{
    margin-left: -15px;
}
.order-section-detail-title>a{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;    
}
.order-section-status-btn{
    border: none;
    width: 117px;
    height: 40px;
}
.order-estimated-time{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: right;
    color: #00B59C;
}
.bottom-border{
    border-bottom: 1px solid #e5dede;
    margin-bottom: 10px;
}

.bottom-border:last-child{
    border-bottom: none;
    margin-bottom: 0px;
}
.order-section-no-order{

}
.order-section-no-order-title{
    margin-bottom: 50px;
}

.order-section-no-order-title>h5{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 36px;
    text-align: center;
    color: #00071B;
    opacity: 0.7;
}

.order-section-no-order-title>h6{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #00071B;
    opacity: 0.5;
}
.order-section-no-order-img{
    margin: auto;
    width: 500px;
}
.order-section-no-order-img>img{
    margin: auto;
}
.order-section-detail{
    padding: 50px;
    border:1px solid #E5E6E8;
    background: #FFFFFF;
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.04);
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.order-section-detail-tracking{
    padding: 20px;
    border:1px solid #E5E6E8;
    margin-bottom: 30px;
    
}
.order-section-detail-tracking-title{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 7, 27, 0.5);
    opacity: 0.8;
}
.order-section-detail-tracking-history>ul>li>p{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #00071B;
    opacity: 0.3;
}
.history-title{
    color: #00071B;
}

.history-title2{
    color: #00071B;
    opacity: 0.3;
}
.order-section-detail-products{
    border: 1px solid #E5E6E8;
    margin-bottom: 30px;
    padding: 20px;
}
.order-section-detail-info{
    border: 1px solid #E5E6E8;
    margin-bottom: 30px;
    padding: 20px;
}

.order-section-detail-info-title{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071B;
}

.order-section-detail-info-data{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #00071B;
    opacity: 0.5;
}
.order-section-detail-cancle-btn{
    width: 100px;
    height: 40px;
    left: 859px;
    top: 980px;
    background: #FFF2F2;
    border: none;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #FE0002;
}
.order-section-detail-info-address{
    border-bottom: 1px solid #E5E6E8;
    padding-top: 15px;
    padding-bottom: 15px;
}

.order-section-detail-info-payment{
    border-bottom: 1px solid #E5E6E8;
    padding-top: 15px;
    padding-bottom: 15px;
}
.order-section-detail-info-payment-history{
    padding-top: 15px;
    padding-bottom: 15px;
}

.order-section-detail-info-payment-history>table{
    width: 45%;
}
.order-section-detail-info-payment-history>table>tbody>tr>td{
    padding-top: 5px;
    padding-bottom: 5px;
}
.order-section-detail-related-product{
    border: 1px solid #E5E6E8;
    margin-bottom: 30px;
    padding: 20px;
}

.order-section-detail-related-title{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    color: #00071B;
    border-bottom: 1px solid #E5E6E8;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.order-section-detail-related-product-list{
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #00071B;
    padding: 10px;
}

.order-section-detail-related-product-price{
    width: 100%;
    display: inline-grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}