@media (max-width: 576px) {
    body {
        overflow-x: hidden;
    }
    .responsive-menu-view {
        display: none;
    }
    .search-box {
        width: 200px !important;
        margin: 8px 0 !important;
        float: right;
    }
    .search-box>input {
        height: 30px;
    }
    .search-form-control {
        background-color: #ffffff99;
        border-color: #848282;
        transition: all 0.5s ease;
    }
    .btn-search {
        font-size: 10px;
        margin-bottom: 7px;
        height: 30px;
    }
    .responsive-search-view {
        display: none;
    }
    .responsive-option-view {
        display: none;
    }
    .footer-section-search-box {
        display: none;
    }
    .social-links a {
        font-size: 12px;
    }
    .product-detail-social-link .link {
        margin-right: 10px!important;
        cursor: pointer;
    }
    .helpline {
        font-size: 7px;
        float: right;
    }
    .m-search-bar {
        display: block;
        margin: auto;
        margin-top: 10px;
    }
    .m-menu-bar {
        display: block;
        margin: auto;
        margin-top: 10px;
        padding-left: 24px;
    }
    .brand-logo {
        height: 42px;
        line-height: 100px;
        margin: 0px 12px auto;
        float: none;
    }
    .menu-header-left {
        text-align: center;
    }
    .white .menu-header {
        /* padding-top: 25px !important; */
    }
    /*Slider Section*/
    .slider-section .slick-prev::before {
        font-size: 15px !important;
    }
    .slider-section .slick-next::before {
        font-size: 15px !important;
    }
    /*product section*/
    .product-section-colI5 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .excl-product-section-colI5 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .new-product-section-colI5 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .flash-product-section-colI5 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .top-sell-product-section-colI5 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .product-section-imgBox {
        display: block !important;
        max-width: 100% !important;
        height: auto !important;
    }
    .flash-product-section-imgBox {
        display: block !important;
        max-width: 100% !important;
        height: 140px !important;
    }
    .flash-product-section-title-text {
        height: auto !important;
    }
    .flash-product-section-title-text>a {
        display: inline-block;
        font-size: 10px;
        padding-left: 10px;
    }
    .flash-product-section-title-text>span {
        padding-top: 10px;
    }
    .new-product-section-title-text>a {
        display: inline-block;
        font-size: 10px;
    }
    .excl-product-section-title-text>a {
        display: inline-block;
        font-size: 10px;
    }
    .product-category-section-imgBox {
        height: 200px;
    }
    .responsive-image-magnify {
        display: none;
    }
    .responsive-image-magnify-image {
        display: inline-block;
    }
    .product-detail-stock {
        display: none;
    }
    .product-section-colI3 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .m-feature-hide {
        display: none;
    }
    .m-feature-show {
        display: block;
        max-width: 100%;
        overflow: hidden;
    }
    .sticker-discount {
        bottom: 463px !important;
    }
    .RubberStamp {
        top: 47px !important;
        left: 29px;
        width: 115px !important;
        height: 60px !important;
    }
    .row-m-product {
        margin-right: -7.5px;
        margin-left: -7.5px;
    }
    .col-m-product {
        padding-right: 7.5px;
        padding-left: 7.5px;
    }
    .ProductnotifyBadge {
        left: 20px !important;
        top: 10px !important;
        padding: 3px 10px !important;
        font-size: 9px !important;
    }
    .portfolio-item img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
    }
    .portfolio-item {
        width: auto !important;
        height: auto !important;
    }
    .portfolio-item__header {
        font-size: 11px !important;
    }
    .feature-themeBtn {
        top: -17px !important;
        font-size: 8px !important;
    }
    /*Footer Section*/
    .overflow-auto {
        background: #ffffffd4;
    }
    /*User Section*/
    .order-viewTitle>p {
        font-size: 10px;
        font-weight: bold;
    }
    .order-viewQty {
        margin-left: 33.33%;
        margin-top: -50px;
        font-size: 10px;
    }
    .order-viewStatus {
        margin-top: -50px;
        font-size: 10px;
    }
    .user-title-view>h6 {
        font-size: 15px !important;
    }
    .swipeable-views-image {
        height: 480px;
        object-fit: cover;
        object-position: top;
    }
    .position-set {
        position: relative;
        transition: all 1s ease;
        left: 0px;
        right: 0px;
    }
    .position-set.start-set {
        left: -25px;
    }
    .position-set.end-set {
        left: 25px;
    }
    /*registration section*/
    .reg-content{
    
        margin-top: 15px!important;
        margin-bottom: 50px!important;
        padding: 0!important;
           
    }
    .reg-content-section {
        margin-top: -50px;
    }
    .reg-content-section-input{
        margin-right: 0px!important;
    }
    .inbox_people{
        display: none;
    }
    .mesgs {
        width: 100%;
    }
    .inbox_msg {
    
        height: 1000px;
    }
    .msg_history {
    height: 892px;
    overflow-y: auto;
    }
}

@media (min-width: 576px) {
    .responsive-image-magnify-image {
        display: none;
    }
    .bottom-navbar {
        display: none;
    }
    .m-search-bar {
        display: none;
    }
    .m-menu-bar {
        display: none;
    }
    .m-feature-show {
        display: none;
    }
    .product-detail-social-link .link {
        margin-right: 10px!important;
        cursor: pointer;
    }
}