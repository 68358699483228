
/*Footer Section*/

.footer-section {
    background: black;
    min-height: 300px;
    padding-top: 10px;
}

.footer-section-item {
    padding-top: 10px;
    padding-bottom: 30px;
}

.footer-section-logo {
    height: 50px;
    line-height: 100px;
    margin: 15px 0px;
    margin-right: 15px;
    float: left;
}

.footer-section-address {
    color: white;
}

.footer-section-quick-link {
    margin: 15px 0px;
    margin-right: 15px;
    float: left;
    color: white;
}

.footer-section-quick-menu {
    color: white;
    float: left;
}

.footer-section-quick-menu>ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-section-quick-menu>ul>li>a {
    color: #333;
    text-decoration: none;
}

.footer-section-social-link {
    margin-right: 15px;
    float: left;
    color: white;
}

.footer-section-search-box {
    width: 350px;
    margin: 21px 0;
    float: left;
}

.footer-section-social-link-item {
    color: #666;
}

.footer-section-social-link-item a {
    color: #666;
    display: inline-block;
    width: 50px;
    font-size: 30px;
}

.footer-section-social-link-item a:hover {
    color: #ff0102;
}

.footer-section-copy-right {
    color: white;
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid #262626;
    vertical-align: bottom;
}
.footer-subscribe-box {
    width: 350px;
    margin: 21px 0;
    float: left;
}
.subscribe-form-control {
    background-color: #ffffff99;
    border-color: #848282;
    transition: all 0.5s ease;
}
.subscribe-obj-focused .subscribe-form-control {
    border-color: #e91818 !important;
    background-color: #fff !important;
}

.subscribe-obj-focused .subscribe-btn-search {
    background-color: #e91818;
}
