
/* Page Section */

.PageSection {
    background-color: #f3f3f3;
    padding-top: 80px;
}

.PageColI5 {
    flex: 33.33%;
    max-width: 33.33%;
}

.PageTitleText {
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
    display: inline-block;
}

.PageTitleText>span {
    border-bottom: 3px solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: inline-block;
}

.PageCard {
    overflow: hidden;
    box-shadow: 0 0 6px 3px #99999940;
    border: 0;
    transition: all 0.5s ease;
}

.PageCard:hover {
    box-shadow: 0 0 6px 6px #77777760;
}

.PageCardBody {
    padding: 0.5rem;
}

.page-banner {
    height: 60px;
    text-align: center;
    padding: 10px;
}

.page-banner>h5 {
    text-align: center;
    margin: auto;
    padding-top: 40px;
}

.PageBrdCmb {
    background-color: transparent;
    border: 1px solid #e3dede;
}

.page-brand {
    border-bottom: 1px solid #d7dfdf;
    padding: 10px;
}


.page-brand >h6{
    font-size: 20px;
    font-weight: bold;
}

.page-brand>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}
.page-brand>ul>li>label {
    padding-right: 15px;
}

.page-service {
    border-bottom: 1px solid #d7dfdf;
    padding: 10px;
}

.pageFilter {
    margin: 10px;
}

.page-filter-option {
    width: 100%;
}
.page-filter-view{
    background: #FFF;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #9993;
    margin-bottom: 10px;
}
.page-filter-option-table {
    padding: 5px !important;
    border: 1px solid #c2bdbd;
    vertical-align: middle;
}

.pageFilterShow {
    padding-left: 10px;
    height: 50px;
    width: 100%;
}

.pageFilterShowTag {
    margin-right: 8px !important;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif;
}

.pageFilterShowSearchTag {
    padding: 4px 8px;
    border: 1px solid hsl(0, 0%, 90%);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif;
    color: hsl(0, 0%, 20%);
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 12px;
    font-weight: 500;
}

.pageFilterShowSearchTagBtn {
    padding: 4px 8px;
    border: 1px solid hsl(0, 0%, 90%);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, arial, sans-serif;
    color: hsl(0, 0%, 20%);
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: 12px;
    font-weight: 500;
}

.pageFilterShowClearText {
    color: hsl(0, 0%, 20%);
    font-size: 12px;
    margin: 0 8px;
    text-decoration: underline;
}

.page-service>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}
.page-service >h6{
    font-size: 20px;
    font-weight: bold;
}

.page-size {
    border-bottom: 1px solid #d7dfdf;
    padding: 10px;
}

.page-size>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}
.page-size >h6{
    font-size: 20px;
    font-weight: bold;
}

.page-size>ul>li>label {
    padding-right: 15px;
}
.page-service>ul>li>label {
    padding-right: 15px;
}

.PageColor {
    padding: 10px;
}
.PageColor >h6{
    font-size: 20px;
    font-weight: bold;
}
.PageColor>Button {
    margin: 10px;
    height: 30px;
    width: 30px;
}

.PagePrice {
    border-bottom: 1px solid #d7dfdf;
    padding-bottom: 20px;
}

.PagePrice >h6{
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;

}
.PagePrice>input {
    width: 75px;
    height: 30px;
    font-size: 12px;
    margin-right: 10px;
    margin-left:15px;
}

.PagePrice>Button {
    margin: 0;
    width: 40px;
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;
    float: right;
}
/*Login Section*/
