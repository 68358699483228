/* Menu Section  */

.menu-section {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background: #00000038;
    transition: all 0.5s ease;
}

.top-header {
    line-height: 38px;
    height: 39px;
    border-bottom: 1px solid #0000001a;
    overflow: hidden;
    transition: all 0.5s ease;
}

.social-links a {
    color: #fff;
    display: inline-block;
    text-align: center;
    width: 30px;
}

.social-links a:hover {
    color: #ff0102;
}
.helpline-box {
    float: right;
}
.helpline {
    color: #fff;
    font-family: "Heebo";
    float: right;

}

.menu-header {
    border-bottom: 1px solid #4a4a4a26;
    transition: all 0.5s ease;
}

.brand-logo {
    height: 50px;
    line-height: 100px;
    margin: 15px 0px;
    margin-right: 15px;
    float: left;
}

.search-box {
    width: 400px;
    margin: 21px 0;
    float: left;
}

.brand-logo {
    /* background: #fff; */
}

.search-form-control {
    background-color: #ffffff99;
    border-color: #848282;
    transition: all 0.5s ease;
}

.search-form-control:focus {
    box-shadow: none;
}

.btn-search {
    color: #ffffff;
    background: #666;
    border: 0;
    transition: all 0.5s ease;
}

.obj-focused .search-form-control {
    border-color: #e91818 !important;
    background-color: #fff !important;
}

.obj-focused .btn-search {
    background-color: #e91818;
}


/*  Menu White Position Style */

.white .top-header {
    height: 0;
    border-bottom: 1px solid #eee;
    background: #fff;
}

.white .menu-header {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.white .social-links a {
    color: #666;
}

.white .social-links a:hover {
    color: #ff0102;
}

.white .helpline {
    color: #ff0102;
    float: right;
}

.white .primary-menu>ul>li>a {
    color: #333;
}

.white .search-form-control {
    background-color: "inherit";
    border-color: #ddd;
}

.white .top-options>.top-icon {
    color: #666;
}


/* Position Style */

.position-lower .top-header {
    height: 39px;
}