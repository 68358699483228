.cart-option {
    background-color: #ffffff;
    color: black;
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%), 0 8px 9px -5px hsl(0deg 0% 8% / 6%);
    border-radius: 0.35rem;
    left: 311px;
    top: 48px;
    padding: 15px;
}

.cart-option-title {
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    padding: 10px;
    height: 40px;
    width: 100%;
}
.cart-item{
    position: absolute;
    background: #f36e36;
    color: #fff;
    top: 1px;
    font-weight: 400;
    right: 0px;
    text-align: center;
    border: 3px solid #f36e36;
    font-size: 10px;
    min-width: 14px;
    line-height: 14px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    align-items: center;
    justify-content: center;
    display: flex;
}