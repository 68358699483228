
/* Slider Section */

.slider-section {
    overflow-x: hidden;
    background: #f3f3f3;
}

.slider-section .slick-prev {
    left: 30px;
    z-index: 1;
}

.slider-section .slick-prev::before {
    font-size: 50px;
}

.slider-section .slick-next {
    right: 60px;
    z-index: 1;
}

.slider-section .slick-next::before {
    font-size: 50px;
}
@media (max-width: 576px) {

.slick-slide img {
    height: 50vh!important;
    object-fit: cover!important;
    object-position: top!important;
}
}