.fashion-menu {
    float: left;
}

.fashion-menu>ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.fashion-menu>ul>li {
    display: inline-block;
    position: relative;
    transition: all 0.5s ease;
}

.fashion-menu>ul>li:hover {
    background-color: #00000015;
}

.fashion-menu>ul>li>a {
    color: #fff;
    text-decoration: none;
}

.fashion-menu>ul>li>a>span {
    display: inline-block;
    padding: 15px 20px;
    font-family: "Noto Sans SC";
    line-height: 50px;
    font-weight: 500;
}

.fashion-menu>ul>li.open .sub-menu-l1 {
    /* display: block; */
    z-index: 2;
    opacity: 1;
    transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 37px, 5px) !important;
    visibility: visible;
}

.sub-menu-l1 {
    visibility: hidden;
    opacity: 0;
    display: block;
    position: absolute;
    z-index: -1;
    background-color: #fff;
    width: 520px;
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%), 0 8px 9px -5px hsl(0deg 0% 8% / 6%);
    border-radius: 1rem;
    left: -16px;
    top: 48px;
    transition: all 0.5s ease;
    transform: perspective(999px) rotateX(-90deg) translateZ(0) translate3d(0, 37px, 0) !important;
    -webkit-backface-visibility: hidden;
}

.sub-menu-l1:before {
    content: "";
    position: absolute;
    top: -13px;
    left: 28px;
    right: auto;
    transition: top 0.35s ease;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid white;
}

.sub-menu-l1>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding: 20px;
    overflow: hidden;
}

.sub-menu-l1>ul>li {
    float: left;
    width: 220px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.sub-menu-l1>ul>li>a {
    color: #333333;
    font-weight: bold;
    display: block;
    font-family: "Noto Sans SC";
    margin-bottom: 10px;
}

.sub-menu-l1>ul>li>a>span>svg {
    display: inline-block;
    margin-right: 15px;
}

.sub-menu-l2>ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.sub-menu-l2>ul>li>a {
    line-height: 31px;
    color: #666;
}

.sub-menu-l2>ul>li:hover>a {
    color: #00ab55;
}

.sub-menu-l2>ul>li .custom-bullet {
    width: 24px;
    height: 24px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 17px;
    left: 23px;
    margin-right: 11px;
}

.sub-menu-l2>ul>li .custom-bullet:before {
    width: 4px;
    height: 4px;
    content: "";
    display: block;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    background-color: #919eab;
}

.sub-menu-l2>ul>li:hover .custom-bullet:before {
    transform: scale(2);
    background-color: #00ab55;
}