
/* Cart Section */

.cart-section {
    padding-top: 130px;
    background: #f3f3f3;
    padding-bottom: 20px;
}

.cart-section-card {
    min-height: 270px;
}

.cart-section-product {
    min-height: 100px;
    text-align: center;
}

.cart-section-product-top {
    text-align: left;
    margin: auto;
    padding: 10px;
    border-bottom: 1px solid #969696;
}

.cart-section-product-list {
    padding-top: 10px;
}

.cart-section-product-img {
    float: left;
}

.cart-section-product-img>img {
    width: 80px;
    height: 80px;
}

.cart-section-summary {
    /*  */
}
.disabled-link {
    pointer-events: none;
}
