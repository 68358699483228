

/*User Section*/

.user-section {
    background-color: #f3f3f3;
    padding-top: 130px;
}

.user-title {
    margin-top: 10px;
    padding-bottom: 10px;

}
.user-title-view{
    background: #FFF;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #9993;
    margin-bottom: 10px;
}


.user-title-view>h6 {
    font-size: 20px;
    padding: 10px;

}

.UserColI5 {
    flex: 0 0 25%;
    max-width: 25%;
}

.UserDashboardsTitleText {
    padding-bottom: 15px;
    margin-bottom: 10px;
    margin-top: 15px;
    display: inline-block;
}

.UserDashboardsTitleText>span {
    border-bottom: 3px solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: inline-block;
}

.user-card {
    overflow: hidden;
    box-shadow: 0 0 6px 3px #99999940;
    border: 0;
    transition: all 0.5s ease;
    margin-bottom: 1.5rem!important;
}

.user-card:hover {
    box-shadow: 0 0 6px 6px #77777760;
}

.UserCardBody {
    padding: 0.5rem;
}

.UserDashboardPageBanner {
    height: 100px;
    text-align: center;
}

.UserDashboardPageBanner>h5 {
    text-align: center;
    margin: auto;
    padding-top: 40px;
}

.user-orders-number {
    font-size: 12px;
}

.user-orders-time {
    font-size: 10px;
}

.user-Orders-manage {
    font-size: 12px;
    float: right;
    vertical-align: top;
}

.user-orders-img {
    float: left;
}

.user-orders-img>img {
    width: 80px;
    height: 80px;
}

.UserOrdersTitle {
    /*  */
}

.UserOrdersQty {
    /*  */
}

.UserOrdersPrice {
    /*  */
}

.UserOrdersStatus {
    /*  */
}

.user-menu {
    padding: 10px;
}

.user-menu> :hover>a {
    color: #00ab55;
}

.user-menu>ul {
    list-style: none;
    margin: 0;
    padding: 5px;
}

.user-menu-link {
    text-decoration: none;
    color: black;
}

.user-menu>ul>li>a {
    line-height: 31px;
    color: black;
}

.user-menu>ul>li:hover>a {
    color: #00ab55;
}

.user-menu>ul>li>a>.scustom-bullet {
    width: 24px;
    height: 24px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 17px;
    left: 12px;
    margin-right: 10px;
}

.user-menu>ul>li>a>.scustom-bullet:before {
    width: 4px;
    height: 4px;
    content: "";
    display: block;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    background-color: #919eab;
}

.user-menu>ul>li:hover>a>.scustom-bullet:before {
    transform: scale(2);
    background-color: #00ab55;
}

.UserDashboardOrder {
    padding: 10px;
}

.UserDashboardOrder>ul {
    list-style: symbols();
    margin: 0;
    padding: 5px;
    padding-left: 30px;
}

.UserDashboardReview {
    padding: 10px;
}

.UserDashboardReview>ul {
    list-style: symbols();
    margin: 0;
    padding: 5px;
    padding-left: 30px;
}

.UserDashboardWish {
    padding: 10px;
}

.UserDashboardWish>ul {
    list-style: symbols();
    margin: 0;
    padding: 5px;
}
